<template>
    <div class="challengeRoundAgreement_container">
        <div class="agreement_main">

            <div>
                <h1>服务协议</h1>
                <div class="agreement_underline">甲方：<span>{{data.agreement_company_name}}</span></div>
                <div class="agreement_underline">乙方：<span>{{data.doctor_name}}</span></div>
                <div class="agreement_margin_p">甲方委托乙方在 “肺癌免疫治疗文献精读全国挑战赛”（以下简称“<span class="agreement_font_bold">本项目</span>
                ”）中提供服务。为明确双方义务及权利，
                经双方协商达成以下协议（以下简称“<span class="agreement_font_bold">本协议</span>”）：</div>

                <h3>1. 权利与义务</h3>
                <p class=" agreement_content_row">1.1 乙方应当持有，且在本协议期限内都将持有安全、妥善及合法履行本协议规定服务内容所需的所有适当的资质、执照和批准。</p>
                <p class=" agreement_content_row">1.2 乙方陈述并保证：（1）乙方在本协议下提供服务已取得必要的批准且不会对其正常执业造成影响；
                    （2）乙方签署本协议、提供本协议规定的服务及收取相应的服务费在目前和未来均不会导致乙方违反任何法律法规或其受雇的任何机构或实体的任何政策或程序；
                    （3）其具备根据行业通行的专业标准提供本协议项下服务所需的专业知识；（4）其将根据行业惯常的专业标准以专业娴熟的方式履行服务；
                    （5）其将遵守履行服务适用的所有法律、规定、法规和准则；及
                    （6）其将遵守适用于提供附件所述服务的所有法律、规定、法规和准则。
                </p>
                <p class="agreement_content_row">1.3 乙方应当按照本协议约定的条款与条件完成甲方委托的工作内容。基于乙方提供的工作内容，甲方应按照本协议约定在乙方的服务完成后支付相应的服务费。</p>
                <p class="agreement_content_row">1.4 乙方确认并保证其提供的服务内容不违反著作权法或侵犯任何第三方在先的合法权利或利益，其在提供服务过程中所使用和/或依赖的信息、数据和材料均是通过合法途径取得，具有所有必要的合法资质和/或授权。</p>
                <p class="agreement_content_row">1.5 乙方在参与本项目及提供服务过程中，如披露具体的病例/病案，乙方应当确保已取得了相关患者的许可或同意，并对其中可能包含的任何个人信息进行匿名化处理后方可用于本项目。</p>
                <p class="agreement_content_row">1.6 乙方保证并承诺，除获得合法授权同意外，其提供的服务内容中不含有任何个人信息(本协议下“个人信息”的定义以《中华人民共和国个人信息保护法》规定为准)，亦不包含任何涉及利用人类遗传资源（包括人类遗传资源材料及人类遗传资源信息）的信息或与之有关的任何资料或信息。为此，乙方应当采取必要合理的努力对服务内容进行匿名化处理。</p>
                <p class="agreement_content_row">1.7 乙方同意并授权本项目的甲方合作方可以免费将其参与项目过程中提供或自行撰写的全部资料、材料用于甲方合作方将来的医疗教育或科学交流等学术活动中；甲方合作方在遵循保护著作权人合法权益的前提下可以按照上述用途进行使用。</p>
                <p class="agreement_content_row">1.8 甲乙双方均应按照中国相关法律法规的规定，履行其各自在本协议下依据法律规定应当履行的与依法纳税相关的责任与义务，包括但不限于由甲方对乙方应缴的个人所得税履行代扣代缴义务，及应纳税人以合法的方式缴纳应缴的增值税。</p>
                <p class="agreement_content_row">1.9 甲方双方一致确认本协议项下的服务费是合理的，并且反映所提供服务的公平市场价值。服务费的提供完全不构成开出、供应、管理、推荐、购买、使用或出售任何商业产品的义务或诱导，且不以任何方式构成与商业实体或商业产品相关的任何业务机会或乙方就此作出或未来可能作出其他决定的条件。</p>
                <p class="agreement_content_row">1.10 甲乙双方陈述并保证，其将遵守一切有关反贿赂及反腐败（《反贿赂法》）的适用法律、法令、法规和准则，包括但不限于美国《反海外腐败法》。</p>
                <p class="agreement_content_row">1.11 甲方不得为以下之目的直接或间接向任何政府官员或《反贿赂法》中规定的其他人员、实体或机构，提供或支付有价值的物品：</p>
                <p class="agreement_content_row agreement_content_two_stage_title">1.11.1 为任何商业实体获得或维持业务;</p>
                <p class="agreement_content_row agreement_content_two_stage_title">1.11.2 不当地影响某一行为或决定而使商业实体获益;</p>
                <p class="agreement_content_row agreement_content_two_stage_title">1.11.3 为商业实体谋取不当利益。</p>
                <p class="agreement_content_row">1.12 甲乙双方承诺保留准确透明的记录以反映交易与记录。若任何一方有任何理由相信另一方可能违反本条款，应当立即以书面形式告知另一方并与 另一方协作对该事实情况进行调查和书面记录。</p>
                <p class="agreement_content_row">1.13 对第1.5-1.8条的违反视为对本协议的实质性违反，守约方有权立即终止本协议。</p>
            </div>

            <div>
                <h3>2. 服务及付款</h3>
                <p class="agreement_content_row">2.1 乙方应提供之服务的范围、服务内容及计费标准见本协议《附表一》。</p>
                <p class="agreement_content_row">2.2 乙方在按本协议要求提供服务后，应按照本协议附表二的要求妥善填写《服务确认单第一联》及《服务确认单第二联》，并由本人在《服务确认单第二联》相应位置签字并注明签字日期，之后该第二联的原件交还甲方存档。</p>
                <p class="agreement_content_row">2.3 甲方在收到乙方提供的《服务确认单第二联》、本人身份证，银行卡复印件以及手机号码后经审查无异议的，按本协议约定的方式向乙方支付相应的服务费以及办理相关税费代扣代缴。</p>
                <p class="agreement_content_row">2.4 第三方代理机构将服务费以银行转账形式支付至下方乙方银行账户:</p>
                <p class="agreement_content_row">账户信息</p>
                <div class="agreement_underline">姓名：<span>{{data.doctor_name}}</span></div>
                <div class="agreement_underline">联系方式：<span>{{data.doctor_phone}}</span></div>
                <div class="agreement_underline">身份证号码：<span>{{data.doctor_id_card_number}}</span></div>
                <div class="agreement_underline">开户银行（包括支行信息）：<span>{{data.doctor_bank}}</span></div>
                <div class="agreement_underline">账号：<span>{{data.doctor_bank_number}}</span></div>
            </div>

            <div>
                <h3>3. 协议的提前终止和终止后的影响</h3>
                <p class="agreement_content_row">3.1 如乙方违反本协议第1.1条和第1.2条约定，甲方有权经书面通知后立即提前终止本协议。</p>
                <p class="agreement_content_row">3.2 因本协议签署时依据的客观情况发生重大变化，致使本协议无法履行的，经任何一方书面通知后可提前终止本协议。</p>
                <p class="agreement_content_row">3.3 任何一方单方面解除本协议的，应当提前30日以书面方式通知另一方，经双方协商后终止本协议。</p>
                <p class="agreement_content_row">3.4 本协议提前终止后，乙方应当（1）向甲方指定的人员清晰交接与本协议下工作相关的事务；（2）乙方应立即停止于本协议下以甲方名义从事的活动。</p>
            </div>

            <div>
                <h3>4. 数据保护</h3>
                <p class="agreement_content_row">4.1 在本协议期间，为实现本协议条所约定之目的，乙方会向甲方提供乙方的姓名、联系方式、身份证号、银行账号等个人信息（以下简称“乙方个人信息”）。甲方将为了妥善履行及记录、保存以及管理本协议之目的将对乙方个人信息在法律允许的范围内进行处理。处理前述敏感个人信息对于甲方履行本协议（例如：向乙方支付报酬）而言是必要的，并且预期依据本协议处理敏感个人信息并不会对顾问的个人权益造成重大不利影响。甲方将在为实现上述目的所需的最短期限内存储个人信息。甲方将运用符合适用中国法律法规要求的组织和技术措施，以确保个人信息处理的安全性。</p>
                <p class="agreement_content_row">4.2 如乙方对个人信息处理提出异议或者行使查阅、复制、更正、删除该等中国法律法规所规定的权利（如适用），乙方可以通过haixin.jin@i-medu.com联系甲方。</p>
            </div>

            <div>
                <h3>5. 法律适用和争议解决</h3>
                <p class="agreement_content_row">5.1 本协议的订立、效力、解释、履行、终止和争议的解决均适用中国法律。</p>
                <p class="agreement_content_row">5.2 因本协议而引起的或与本协议有关的一切争议，应当通过友好协商解决。如协商不成，任何一方可随时将该争议提交该方所在地有管辖权的法院通过诉讼途径解决。</p>
            </div>

            <div>
                <h3>6. 各方一致同意：对于（1）本协议，（2）各方之间的所有往来通讯，以及（3）一方向其他方提供的或从其他方收到的所有信息及其他材料（统称“<span class="agreement_font_bold">保密信息</span>”），除非披露保密信息的一方另行指示或法律另有规定，否则接收保密的各方应对保密信息应予以保密。</h3>
            </div>

            <div>
                <h3>7. 乙方在任何情况下均不得出让、让与、转让或以其他方式转移其在本协议项下的任何权利或委任其在本协议项下的任何职责。此外，乙方亦不得向第三方分包或让与任何部分本协议项下的服务。当乙方获知其无法完成任何方面的服务时，应尽快告知甲方。</h3>
            </div>

            <div>
                <h3>8. 协议自甲方加盖公章且经乙方签字之日起生效，直至双方完全履行完毕其在本协议下的义务为止。</h3>
            </div>

            <div>
                <h3>9. 本协议一式两份，甲、乙双方各执一份，具有同等效力。</h3>
            </div>
        </div>
        <div class="agreement_sign_info">
            <div class="agreemtn_sign_them">
                <div class="agreement_sign_name"><span>甲方：</span>{{data.agreement_company_name}}</div>
                <div style="display: flex;margin-bottom: 4vw">
                    <div>签字：</div>
                    <div style="flex: 1 0 0;border-bottom: 1px solid #000;"></div>
                </div>
                <div class="agreement_sign_date">日期：{{$tools.getDate(data.meet_sign_time,"yyyy年MM月dd日")}}</div>
            </div>
            <div class="agreemtn_sign_owner">
                <div class="agreement_sign_name">
                    <span>乙方：</span> 
                    <customButton  @click="doSignName(0)" v-if="!(data.meet_sign_name?.split(',')[0] || this.signData[0]) ">
                        <div>
                            <span>签名</span>
                        </div>
                    </customButton>
                    <img :src="data.meet_sign_name?.split(',')[0] || this.signData[0]" @click="doSignName(0)" v-else alt="">
                </div>
                <div class="agreement_sign_date">日期：{{$tools.getDate(data.meet_sign_time,"yyyy年MM月dd日")}}</div>
            </div>
        </div>

        <div class="agreement_main attached_table">
            <div>
                <h1>《服务协议》附表一</h1>
                <h3>【此处应以表格的方式列明乙方（1）提供服务的明细、（2）计价单位、（3）单位价格、（4）总价等信息】专家服务费包含如下服务内容:</h3>
                <div class='agreement_table agreement_table_one'>
                    <div class="agreement_row">
                        <div class="agreement_td">提供服务明细</div>
                        <div class="agreement_td">服务费（税后）</div>
                    </div>
                    <div class="agreement_row">
                        <div class="agreement_td">
                            1篇文献解读及文献相关病例分享，具体如下：<br>
                            （1）文献原文阅读；<br>
                            （2）对服务商提供的约8页标准幻灯内容进行阅读，并基于自己对文献原文的理解对标准幻灯进行修改、完善，融入自己的观点；<br>
                            （3）根据文献及完善后的标准幻灯内容匹配1例病例，并就匹配的1例病例（不包含任何个人信息），自行整理并制作至少3页病例幻灯；<br>
                            （4）对最终成果进行音频录制，确保最终录制成品的质量，且成品时长至少5分钟；<br>
                        </div>
                        <div class="agreement_td">￥1,500 </div>
                    </div>
                </div> 
            </div> 
        </div>

        <div class="agreement_main attached_table">
            <div>
                <h1>《服务协议》附表二</h1>
                <h1>《服务确认单》</h1>
                <h3>说明：本附件中的《服务确认单》一式两份，第一联由乙方自行留存，第二联应在乙方按照《服务协议》的要求提供完毕全部服务后由其本人在签字处签名并注明签字日期，并将该第二联的原件交由甲方存档。</h3>
                <p class="agreement_content_row">《服务确认表第一联》（乙方留存）</p>
                <div class='agreement_table agreement_table_two'>
                    <div class="agreement_row">
                        <div class="agreement_td">项目编号</div>
                        <div class="agreement_td">服务内容（根据实际情况填写，应明确具体的工作内容和预计耗时等）</div>
                    </div>
                    <div class="agreement_row">
                        <div class="agreement_td">1</div>
                        <div class="agreement_td">文献原文阅读</div>
                    </div>
                    <div class="agreement_row">
                        <div class="agreement_td">2</div>
                        <div class="agreement_td">对服务商提供的约8页标准幻灯内容进行阅读，并基于自己对文献原文的理解对标准幻灯进行修改、完善，融入自己的观点</div>
                    </div>
                    <div class="agreement_row">
                        <div class="agreement_td">3</div>
                        <div class="agreement_td">根据文献及完善后的标准幻灯内容匹配1例病例</div>
                    </div>
                    <div class="agreement_row">
                        <div class="agreement_td">4</div>
                        <div class="agreement_td">就匹配的1例病例，自行整理并制作至少3页病例幻灯</div>
                    </div>
                    <div class="agreement_row">
                        <div class="agreement_td">5</div>
                        <div class="agreement_td">对最终成果进行音频录制，确保最终录制成品的质量，且成品时长至少5分钟</div>
                    </div>
                </div> 
            </div> 
        </div>
        <!-- 分割线 -->
        <div class="challengeRoundAgreement_split_line">
            <div class="split_line"></div>
            <div>裁剪线</div>
            <div class="split_line"></div>
        </div>

        <div class="agreement_main attached_table">
            <div>
                <p class="agreement_content_row">《服务确认表第二联》（交甲方存档）</p>
                <div class='agreement_table agreement_table_two'>
                    <div class="agreement_row">
                        <div class="agreement_td">项目编号</div>
                        <div class="agreement_td">服务内容（根据实际情况填写，应明确具体的工作内容和预计耗时等）</div>
                    </div>
                    <div class="agreement_row">
                        <div class="agreement_td">1</div>
                        <div class="agreement_td">文献原文阅读</div>
                    </div>
                    <div class="agreement_row">
                        <div class="agreement_td">2</div>
                        <div class="agreement_td">对服务商提供的约8页标准幻灯内容进行阅读，并基于自己对文献原文的理解对标准幻灯进行修改、完善，融入自己的观点</div>
                    </div>
                    <div class="agreement_row">
                        <div class="agreement_td">3</div>
                        <div class="agreement_td">根据文献及完善后的标准幻灯内容匹配1例病例</div>
                    </div>
                    <div class="agreement_row">
                        <div class="agreement_td">4</div>
                        <div class="agreement_td">就匹配的1例病例，自行整理并制作至少3页病例幻灯</div>
                    </div>
                    <div class="agreement_row">
                        <div class="agreement_td">5</div>
                        <div class="agreement_td">对最终成果进行音频录制，确保最终录制成品的质量，且成品时长至少5分钟</div>
                    </div>
                </div> 
            </div> 
        </div>

        <p style="margin-top: 5vw;" class="agreement_content_row">本人确认已经按照《服务协议》的要求如实、完整地提供了上表中所载明的各项服务。</p>

        <div class="agreement_sign_info">
            <!-- <div class="agreemtn_sign_them">
                <div class="agreement_sign_name"><span>甲方：</span>{{data.project_agreement_company_name}}</div>
                <div class="agreement_sign_date">{{$tools.getDate(data.meet_sign_time,"yyyy年MM月dd日")}}</div>
            </div> -->
            <div class="agreemtn_sign_owner">
                <div class="agreement_sign_name">
                    <span>本人签字：</span> 
                    <customButton  @click="doSignName(1)" v-if="!(data.meet_sign_name?.split(',')[1] || this.signData[1]) ">
                        <div>
                            <span>签名</span>
                        </div>
                    </customButton>
                    <img :src="data.meet_sign_name?.split(',')[1] || this.signData[1]" @click="doSignName(1)" v-else alt="">
                </div>
                <div class="agreement_sign_date">签字日期：{{$tools.getDate(data.meet_sign_time,"yyyy年MM月dd日")}}</div>
            </div>
        </div>
    </div>
</template>
<script>
import customButton from "@/components/unit/customButton"

export default{
    name:"challengeRoundAgreement",
    props:{
        agreementData:{
            type:Object,
            default:()=>{
                return {}
            }
        },
        agreementFields:{
            type:Object,
            default:()=>{
                return []
            }
        },
        signData:{
            type:Array,
            default:()=>{
                return []
            }
        }
    },
    data(){
        return {
            data:{}
        }
    },
    components: {
       customButton
    },
    mounted(){
        this.initData(this.agreementData,this.agreementFields)
        setTimeout(()=>{
            this.$emit("initScroll")
        },1000)
    },
    methods:{
        initData(data){
            console.log(data)
            this.data = data
        },
        verifyData(){
            if(!this.signData[0]){
                this.$tips.error({text:"请先签署第一个签名"})
                return false;
            }
            if(!this.signData[1]){
                this.$tips.error({text:"请先签署第二个签名"})
                return false;
            }
            return true;
        },
        doSignName(index){
            this.$emit("doSignName",index)
        },
    }
}
</script>
<style>
.challengeRoundAgreement_container{
    word-break: break-all;
}
.agreement_sign_info{
    display: flex;
}
.agreemtn_sign_them{
    margin-right: 1vw;
}
.agreement_sign_info>div{
    flex:1 0 0;
    margin-top:6vw;
}
.agreement_sign_name{
    height:10vw;
    display: flex;
    align-items: center;
}
.agreement_sign_name span{
    white-space: nowrap;
}
.agreement_sign_name img{
    width:24vw;
    height:auto;
}
.agreement_margin_p{
    margin: 3vw 0;
}
.agreement_font_bold{
    font-weight: 600;
}
.agreement_content_two_stage_title{
    text-indent:8vw;
}
.attached_table{
    margin-top: 5vw;
}
.challengeRoundAgreement_container .agreement_table{
    width:100%;
    height:100%;
    border:1px solid #000;
  }
.challengeRoundAgreement_container  .agreement_row{
    display: flex;
    min-height:6vw;
  }
  .agreement_td{
    /* font-size: 4vw; */
    padding:1vw 2vw;
    line-height: 6vw;
    border-bottom:1px solid #000;
    border-right:1px solid #000;
    word-break: break-all;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
.challengeRoundAgreement_container  .agreement_td div{
    line-height: 4.8vw;
  }
.challengeRoundAgreement_container .agreement_table_one .agreement_row .agreement_td:first-child{
    flex:1 0 0;
  }
.challengeRoundAgreement_container .agreement_table_one .agreement_row .agreement_td:last-child{
    flex:35vw 0 0;
    border-right:0;
}
.challengeRoundAgreement_container .agreement_table_two .agreement_row .agreement_td:first-child{
    flex:21vw 0 0;
  }
.challengeRoundAgreement_container .agreement_table_two .agreement_row .agreement_td:last-child{
    flex:1 0 0;
    border-right:0;
}
.challengeRoundAgreement_container  .agreement_row:last-child>.agreement_td{
    border-bottom:0;
}
.challengeRoundAgreement_split_line{
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 5vw;
    margin-top: 5vw;
}
.split_line{
    height: 0px;
    flex: 1 0 0;
    border-bottom: #000 1vw dashed;
}
</style>